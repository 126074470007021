import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from 'store';
import { ToastContainer } from 'react-toastify';
import ContextProvider from 'context/Provider';
import * as Sentry from "@sentry/react";
import sentryJSON from './sentry-release.json';

if(window.REACT_APP_SENTRY_ENV !== ''){
  Sentry.init({
    dsn: window.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: window.REACT_APP_SENTRY_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: window.REACT_APP_SENTRY_SAMPLE_RATE,
    release: sentryJSON.release,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <ContextProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
            <ToastContainer limit={3} />
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </ContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
